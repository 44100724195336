// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/profbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media print{#print-iframe{-webkit-print-color-adjust:exact;-moz-print-color-adjust:exact;-ms-print-color-adjust:exact;print-color-adjust:exact}.profdialog{display:flex;flex-direction:row;background:#f5f5f5}.profdialog_left{flex:1;padding:.10417rem .10417rem;background-color:#fff}.profdialog_left_num{margin-top:.10417rem}.profdialog_left_bg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;background-repeat:no-repeat;padding:.20833rem .15625rem .20833rem .15625rem}.profdialog_left_bg_stationname{color:#424242;font-size:.09375rem}.profdialog_left_bg_title{color:#ad8427;font-size:.375rem;margin-top:.26042rem;font-weight:700}.profdialog_left_bg_profs{margin-top:.41667rem;text-align:left;font-size:.07292rem;color:#212121;font-weight:700}}", ""]);
// Exports
module.exports = exports;
